@import url('https://fonts.googleapis.com/css?family=Roboto:100,500&display=swap&subset=latin-ext');
@import '../node_modules/normalize.css/normalize.css';

$blue: #5796e3;
$dark-blue: #4481da;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: $blue;
  color: white;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 100;
}

app-root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bold {
  font-weight: 500;
}

button {
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  color: white;
  outline: none;
}

footer {
  height: 30px;
  line-height: 30px;
  padding-left: 30px;
  background-color: white;
  color: black;
  font-size: 0.8rem;
  margin-top: auto;
}
